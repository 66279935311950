import React from "react";
import "./Feature2.css";

const Feature2 = () => {
  return (
    <div className="feature2-container">
      <p className="feature2-title">
        <b>Discover the Difference</b>
      </p>
      <p className="feature2-text">
        We provide Interior & Exterior house painting service in Dublin,
        Ireland. Transform your living spaces with paint where
        expertise meets excellence in interior and exterior house painting
        services.
      </p>
      <p className="feature2-text">
        Protecting your property providing a clean & tidy home with a peace of
        mind, always maintaning the highest standards so you can enjoy a fresh,
        vibrant living space.
      </p>
      <p className="feature2-text">
        Many years of experience in the industry providing high quality service
        in Dublin. Using top brands of paint and supply all paint as part of our
        service.
      </p>
      <p className="feature2-text">
        Fully Insured and VAT Registered, Our clients trust us to deliver
        quality service, and your satisfaction is our top priority.
      </p>
      <p className="feature2-text">
        We work in in a professional manner with a careful preparation, covering
        floors with dust sheets, protecting carpets, furniture, and maintain the
        interior of your home. We minimise dust particles, and use the best
        painting tools and materials to offer an ever-lasting finish.
      </p>
      <p className="feature2-text">
        BeePainting - your top trusted painter partner in transforming
        spaces with precision, careful touch of creativity.
      </p>
    </div>
  );
};
export default Feature2;
