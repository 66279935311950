import React from "react";
import "./Quote.css";

import mail from "../Images/NavBar/mail.svg";
import phone from "../Images/NavBar/phone.svg";
import whatsApp from "../Images/NavBar/WhatsAppButtonGreenSmall.svg";

import manPainting from "../Images/Quote/manPainting.jpg";

import EmailSend from "./EmailSend";

export default function Quote() {
  return (
    <div className="quoteBody">
      <header className="quoteHeader">
        <p className="h4 quoteTitle">Please fill in the following details</p>
        <hr />
        <p>
          Once Submitted we will get in touch with approximately 24-48 hrs.
          Please provide a short video/photos so it speeds up the overall
          retrieval of your Quote.
        </p>
        <p>
          Free Quote availability Online & Also Free Painting Walk In
          Consultation Booking in Dublin, Ireland.
        </p>
        <p>
          Our team will be in touch within next working days to discuss further.
        </p>
      </header>

      <div className="quoteMain">
        <div>
        <p className="h4 text-center">Contact Details</p>
          <div className="contactInfoQuote">
            <a
              href="tel:+353894024355"
             
            >
              <img
                src={phone}
                alt="phone"
        
              />
              Call Us: 089 402 4355
            </a>
            <a
              href="https://wa.me/353894024355?text=I'm%20inquiring%20about%20your%20services"
              aria-label="Chat on WhatsApp"
             
            >
              <img
                src={whatsApp}
                alt="Chat on WhatsApp"
              
              />
            </a>
            <a
              href="mailto:beepaintingdublin@gmail.com"
             
            >
              <img
                src={mail}
                alt="email"
               
              />
              beepaintingdublin@gmail.com
            </a>
          </div>
          <img
            alt="manPainting"
            src={manPainting}
            loading="lazy"
            className="manPaintingQuoteImg"
          />
        </div>
        <EmailSend />
      </div>

      <div className="quoteFooter">
        <p>
          After submitting the form, Our Team Member will contact you to discuss
          requirements. If possible please include a short video or photo of the
          specific area that is required to be painted, so it speeds up the
          quoting process. We appreciate your patience.
        </p>
      </div>
    </div>
  );
}
